<template>
    <!--        JUST COPY THE CONTENTS OF THE FILE!!!       -->
<div class="gridWrap">
    <div class="grid" :style="{ gridTemplateColumns: gridTemplateColumns, gridTemplateRows: gridTemplateRows }">
        <v-card class="card">
            <v-card-text class="text-center">
                <h2 style="font-size: 21px; ">{{ totals.unprocessedFileTotal }}</h2>
                <v-card-subtitle class="mt-0 pa-0" style="font-size: 12px; word-wrap: normal;">Unprocessed Files</v-card-subtitle>
            </v-card-text>
        </v-card>
        <v-card class="card">
            <v-card-text class="text-center">
                <h2 style="font-size: 21px">{{ totals.notReadyToInvoiceTotal }}</h2>
                <v-card-subtitle class="mt-0 pa-0" style="font-size: 12px; word-wrap: normal;">Not Ready to Invoice</v-card-subtitle>
            </v-card-text>
        </v-card>
        <v-card class="card">
            <v-card-text class="text-center">
                <h2 style="font-size: 21px">{{ totals.documentationTotal }}</h2>
                <v-card-subtitle class="mt-0 pa-0" style="font-size: 12px; word-wrap: normal;">Documents</v-card-subtitle>
            </v-card-text>
        </v-card>
        <v-card class="card">
            <v-card-text class="text-center">
                <h2 style="font-size: 21px">{{ totals.openFilesTotal }}</h2>
                <v-card-subtitle class="mt-0 pa-0" style="font-size: 12px; word-wrap: normal;">Open Files</v-card-subtitle>
            </v-card-text>
        </v-card>
    </div>
</div>
</template>

<script>
export default {
    props: {
        widget: {
            type: String,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        widgetIndex: {
        type: Number,
        required: true,
        },
        activeWeeks: {
            type: Array,
            required: true,
        },
        filter: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        w: 4,
        h: 4,
        cellWidth: 0,
        cellHeight: 0,
        cellMinWidth: 2,
        cellMaxWidth: 4,
        cellMinHeight: 1,
        cellMaxHeight: 4,
        totals: {
          unprocessedFileTotal: 0,
          notReadyToInvoiceTotal: 0,
          documentationTotal: 0,
          openFilesTotal: 0,
        },
        loading: false,
        data: [],
        dataQuery: undefined,
        dataTimeout: undefined
    }),
    mounted() {
// DO NOT CHANGE THE CODE BELOW!!!
// This code is used to calculate the number of columns and rows the widget takes up when it is resized
// This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
// We need these values to resize / reorder the contents of the widget when it is resized
// This also takes into account the resizing of the grid itself
        this.$nextTick(() => {
            const path = this.widget
            const grid = document.getElementById('grid');
            const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
            let gridWidth = grid.offsetWidth;
            let gridHeight = grid.offsetHeight;
            let cellWidth = gridWidth / 25;
            this.cellWidth = cellWidth;
            let cellHeight = gridHeight / 14;
            this.cellHeight = cellHeight;
            const gridResizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    gridWidth = entry.contentRect.width;
                    gridHeight = entry.contentRect.height;
                    cellWidth = gridWidth / 25;
                    this.cellWidth = cellWidth;
                    cellHeight = gridHeight / 14;
                    this.cellHeight = cellHeight;
                }
            });
            gridResizeObserver.observe(grid, { box: 'content-box' });
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const widgetWidth = entry.contentRect.width;
                    const widgetHeight = entry.contentRect.height;
                    const widgetColumns = Math.round(widgetWidth / cellWidth);
                    this.w = widgetColumns;
                    const widgetRows = Math.round(widgetHeight / cellHeight);
                    this.h = widgetRows;
                }
            });
            resizeObserver.observe(widget, { box: 'content-box' });
        });
        // DO NOT CHANGE THE CODE ABOVE!!!
    },
    created() {
        this.getFilteredData();
    },
    watch: {
        columns() {
            this.w = this.columns;
        },
        rows() {
            this.h = this.rows;
        },
    },
    computed: {
        // visibleCards() {
        //     const maxCardsWidth = Math.floor(this.w / this.cellMinWidth);
        //     const maxCardsHeight = Math.floor(this.h / this.cellMinHeight);
        //     let max = maxCardsWidth * maxCardsHeight;
        //     console.log(max)
        //     return this.cards.slice(0, max);
        // },
        gridTemplateColumns() {
            return `repeat(auto-fit, minmax(${this.widthMinMax(this.cellMinWidth, this.cellMaxWidth).low}px, 1fr)`;
        },
        gridTemplateRows() {
            return `repeat(auto-fit, minmax(${this.heightMinMax(this.cellMinHeight, this.cellMaxHeight).low}px, 1fr)`;
        },

    },
    methods: {
        widthMinMax(min, max) {
            let low = (min * this.cellWidth) - 2;
            let high = (max * this.cellWidth) - 2;
            return { low, high };
        },
        heightMinMax(min, max) {
            let low = (min * this.cellHeight) - 2;
            let high = (max * this.cellHeight) - 2;
            return { low, high };
        },
        async getFilteredData() {
          if (this.loading) {
            clearTimeout(this.dataTimeout);
          }
          if (this.loading && this.dataQuery) {
            this.dataQuery.abort();
          }
          this.loading = true;
          this.dataTimeout = setTimeout(async () => {
            this.dataQuery = new AbortController();
            let filterFinal = null
            if(this.filter && this.filter.vesselVoyage && this.filter.vesselVoyage?.voyage) {
                filterFinal = {
                    ...this.filter,
                    vesselVoyage: {
                        voyage: this.filter.vesselVoyage.voyage,
                        id: this.filter.vesselVoyage.id,
                    }
                }
            } else {
                filterFinal = {...this.filter}
            }
            const signal = this.dataQuery.signal;
            let filter = {
              ...filterFinal
            };
            this.data = await this.$API.getDocumentDashboardData({
              params: filter,
              signal,
            });
            let unprocessedFileTotal = this.data.reduce(
              (acc, week) => acc + week.unprocessedFiles,
              0
            );
            let notReadyToInvoiceTotal = this.data.reduce(
              (acc, week) => acc + week.notReadyToInvoice,
              0
            );
            let documentationTotal = this.data.reduce(
              (acc, week) => acc + week.documentTotal,
              0
            );
            let openFilesTotal = this.data.reduce(
              (acc, week) => acc + week.openFiles,
              0
            );
            this.totals = {
              unprocessedFileTotal,
              notReadyToInvoiceTotal,
              documentationTotal: documentationTotal,
              openFilesTotal,
            },
            this.loading = false;
          }, 500);
        },
    }
};
</script>  

<style scoped>
.grid {
    display: grid;
    contain: content;
    width: 100%;
    height: 100%;
    gap: 0.4rem;
}

.gridWrap {
    height: inherit;
    width: inherit;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.gridWrap>* {
    scroll-snap-align: start;
}

.card {
    background-color: var(--v-toolbar-lighten1) !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>